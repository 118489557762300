import { ZustandLanguageStore } from './types';

export const getLanguagesSelector = (state: ZustandLanguageStore) =>
  state.languages;

export const getActiveLanguageNameSelector = (state: ZustandLanguageStore) =>
  getLanguagesSelector(state)?.name;

export const getLanguageDictionarySelector = (state: ZustandLanguageStore) =>
  getLanguagesSelector(state)?.dictionary;

// ===================================================  ACTIONS ===================================================
export const getAndSetLanguagesAction = (state: ZustandLanguageStore) =>
  state.getAndSetLanguages;

export const setInitialLanguageAction = (state: ZustandLanguageStore) =>
  state.setInitLanguage;

export const setLanguagesAction = (state: ZustandLanguageStore) =>
  state.setLanguages;
