import { getLanguageDictionarySelector } from 'store/languages/selectors';
import { useZustandLanguagesStore } from 'store/languages/store';

export default function useTranslate(name: string, specValue: string) {
  const dictionary = useZustandLanguagesStore(getLanguageDictionarySelector);

  const getText = (
    path: string,
    obj = dictionary,
    separator = '.',
    specValue = ''
  ) => {
    try {
      const properties = path?.split(separator);

      let result =
        properties.reduce((prev: any, curr: any) => prev && prev[curr], obj) ||
        path;

      if (result.includes('[INJECT_VALUE]')) {
        result = result
          .replaceAll('[INJECT_VALUE]', '')
          .replaceAll('[VALUE]', specValue);
      }

      return result;
    } catch (error) {
      console.log('🚀 ~ useTranslate ~ error:', error);

      return path || '';
    }
  };

  return {
    getText,
    name: getText(name, undefined, undefined, specValue),
  };
}
