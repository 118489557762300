import { create } from 'zustand';
import { EAppState, ZustandAppStateStore } from './types';

export const useZustandAppStateStore = create<ZustandAppStateStore>((set) => ({
  state: EAppState.Loading,

  setAppState: (state) => {
    set({ state });
  },
}));
