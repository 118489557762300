import Text from 'components/Text';
import BigCard from '../Cards/BigCard';
import { PRIZE_LIST } from './constants';

import styles from './Prizes.module.css';

const Prizes = () => (
  <div className={styles.wrapperPrizes}>
    <div className={styles.title}>
      <Text idT="prizeTitle" />
    </div>

    <div className={styles.content}>
      {PRIZE_LIST.map((p) => (
        <BigCard {...p} key={p.name} />
      ))}
    </div>
  </div>
);

export default Prizes;
