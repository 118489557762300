export const sleep = (ms: number) => new Promise((res) => setTimeout(res, ms));

export const getRandomInt = (min: number, max: number) => {
  min = Math.ceil(min);
  max = Math.floor(max);

  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const getValueByIdFromUrl = (key: string, path: string): string => {
  const url = new URL(path);

  const value = url.searchParams.get(key) || '';

  return value;
};
