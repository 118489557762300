import { GameResult } from './types';

export const LOSS: GameResult = {
  img: '/assets/loss.webp',
  name: 'tryAgain',
  isResult: false,
  prize: 0,
};
export const WIN: GameResult = {
  img: '/assets/usdt.webp',
  name: 'usdt',
  isResult: false,
  prize: 0,
};
export const ETH: GameResult = {
  img: '/assets/eth.webp',
  name: 'eth',
  isResult: false,
  prize: 0,
};

export const LIST: GameResult[] = [
  LOSS,
  {
    img: '/assets/usdt.webp',
    name: '100 USDT',
    isResult: false,
    prize: 0,
  },
  {
    img: '/assets/eth.webp',
    name: '5.00',
    isResult: false,
    prize: 0,
  },
  {
    img: '/assets/btc.webp',
    name: '1.00',
    isResult: false,
    prize: 0,
  },
  {
    img: '/assets/loss.webp',
    name: 'tryAgain',
    isResult: false,
    prize: 0,
  },
  LOSS,
];
