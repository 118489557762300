import { ZustandGameStore } from './types';

export const getGameStateSelector = (store: ZustandGameStore) => store.state;

export const getGameResultSelector = (store: ZustandGameStore) =>
  store.gameResult;

export const getGameStakeSelector = (store: ZustandGameStore) => store.stake;

export const getCurrencyNameSelector = (store: ZustandGameStore) =>
  store.currencyName;

export const getBetsSelector = (store: ZustandGameStore) => store.bets;

export const getBalanceSelector = (store: ZustandGameStore) => store.balance;

// Actions

export const setGameStateAction = (store: ZustandGameStore) =>
  store.setGameState;

export const setBalanceAction = (store: ZustandGameStore) => store.setBalance;

export const setStakeAction = (store: ZustandGameStore) => store.setStake;

export const runGameStateAction = (store: ZustandGameStore) => store.runGame;

export const runGameUiAction = (store: ZustandGameStore) => store.runUiGame;

export const setGameResultAction = (store: ZustandGameStore) =>
  store.setGameResult;

export const setGameStakeInfoAction = (store: ZustandGameStore) =>
  store.setStakeInfo;
