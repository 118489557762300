export interface ZustandGameStore extends ZustandGameEntities {
  setGameState: (s: EGameState) => void;
  runGame: () => void;
  runUiGame: () => void;
  setStake: (stake: number) => void;
  setStakeInfo: (p: StakeInfo) => void;
  setGameResult: (payload: GameResultPayload) => void;
  setBalance: (payload: number) => void;
}

type StakeInfo = {
  bets: number[];
  stake: number;
  currencyName: string;
};

type GameResultPayload = {
  isWin: boolean;
  prize: number;
  isError?: boolean;
  isRealMoney?: boolean;
};

interface ZustandGameEntities {
  state: EGameState;
  gameResult: null | GameResult;
  stake: number;
  bets: number[];
  currencyName: string;
  balance: number;
}

export const enum EGameState {
  Idle,
  PrepareGameInfo,
  StartGame,
  ResultGame,
  WinAnimation,
}

export type GameResult = {
  img: string;
  name: string;
  isResult: boolean;
  isRealMoney?: boolean;
  prize: number;
};
