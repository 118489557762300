export const PRIZE_LIST = [
  {
    img: '/assets/usdt.webp',
    name: 'usdtPrize',
    percent: 'usdtChance',
  },
  {
    img: '/assets/eth.webp',
    name: 'ethPrize',
    percent: 'ethChance',
  },
  {
    img: '/assets/btc.webp',
    name: 'bitcoinPrize',
    percent: 'bitcoinChance',
  },
  {
    img: '/assets/loss.webp',
    name: 'tryAgain',
    percent: 'tryAgainChance',
  },
];
