import { useEffect } from 'react';
import cln from 'classnames';

import {
  getBetsSelector,
  getCurrencyNameSelector,
  getGameResultSelector,
  getGameStakeSelector,
  getGameStateSelector,
  runGameStateAction,
  setStakeAction,
} from '../../store/game/selectors';
import { useZustandGameStore } from '../../store/game/store';
import { EGameState } from '../../store/game/types';
import { useZustandAppStateStore } from '../../store/appState/store';
import { getAppStatusSelector } from '../../store/appState/selectors';
import { EAppState } from '../../store/appState/types';

import { ELootBoxCommand } from '../../HOC/HOCInitGame/types';

import styles from './ActionButtons.module.css';
import Text from 'components/Text';

const ActionButtons = () => {
  const gameState = useZustandGameStore(getGameStateSelector);

  const appState = useZustandAppStateStore(getAppStatusSelector);

  const runGame = useZustandGameStore(runGameStateAction);

  const stake = useZustandGameStore(getGameStakeSelector);

  const bets = useZustandGameStore(getBetsSelector);

  const setStake = useZustandGameStore(setStakeAction);

  const gameResult = useZustandGameStore(getGameResultSelector);

  const currencyName = useZustandGameStore(getCurrencyNameSelector);

  const handleRunGame = () => {
    if (appState !== EAppState.Available) {
      return;
    }

    runGame();
  };

  const handleChangeBet = (stake: number) => () => {
    setStake(stake);
  };

  useEffect(() => {
    if (gameState === EGameState.PrepareGameInfo && !gameResult && stake) {
      window?.parent?.postMessage(
        {
          type: ELootBoxCommand.GetResult,
          payload: {
            status: true,
            stake,
          },
        },
        '*'
      );
    }
  }, [gameResult, gameState, runGame, stake]);

  return (
    <div className={styles.wrapperActionButtons}>
      <div
        className={cln(styles.spinButton, {
          [styles.disableBtn]:
            (gameState !== EGameState.Idle &&
              gameState !== EGameState.ResultGame &&
              gameState !== EGameState.WinAnimation) ||
            appState !== EAppState.Available,
        })}
        onClick={handleRunGame}
      >
        {gameState !== EGameState.Idle &&
        gameState !== EGameState.ResultGame &&
        gameState !== EGameState.WinAnimation ? (
          <div className={styles.center}>
            <Text idT="loading" />
          </div>
        ) : (
          <>
            <span className={styles.spinText}>
              <Text idT="spin" />
            </span>
            <span className={styles.sum}>
              {stake} {currencyName}
            </span>
          </>
        )}
      </div>

      <div className={styles.row}>
        {bets.map((b) => (
          <div
            className={cln(styles.btn, styles.black, {
              [styles.active]: stake === b,
            })}
            key={b}
            onClick={handleChangeBet(+b)}
          >
            {b}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ActionButtons;
