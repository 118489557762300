import React from 'react';

import styles from './Loader.module.css';
import { useZustandAppStateStore } from '../../store/appState/store';
import { getAppStatusSelector } from '../../store/appState/selectors';
import { EAppState } from '../../store/appState/types';

const Loader = () => {
  const appState = useZustandAppStateStore(getAppStatusSelector);

  if (appState !== EAppState.Verification && appState !== EAppState.Loading) {
    return null;
  }

  return (
    <div className={styles.wrapperLoader}>
      <div className={styles.loader} />
    </div>
  );
};

export default Loader;
