import { getBalanceSelector, getGameStateSelector } from 'store/game/selectors';
import { useZustandGameStore } from 'store/game/store';
import { EGameState } from 'store/game/types';

import Text from 'components/Text';

import styles from './Balance.module.css';

const Balance = () => {
  const balance = useZustandGameStore(getBalanceSelector);

  const gameState = useZustandGameStore(getGameStateSelector);

  return (
    <div className={styles.wrapperBalance}>
      <span>
        <Text idT="balance" />
      </span>

      {(gameState === EGameState.Idle ||
        gameState === EGameState.ResultGame ||
        gameState === EGameState.WinAnimation) && (
        <span className={styles.balance}>{balance}</span>
      )}
    </div>
  );
};

export default Balance;
