export const enum ELootBoxCommand {
  GameIsLoaded = 'loot-box-game-is-ready',
  VerificationStatus = 'app-verified',
  // UserAuthorizeStatus = 'user-auth-status',
  SetGameSettings = 'set-game-settings',
  GetResult = 'get-loot-box-result',
  GameResult = 'game-result-loot-box',

  BonusBalance = 'game-bonus-balance',
}
