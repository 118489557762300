import cln from 'classnames';

import Text from 'components/Text';

import styles from './BigCard.module.css';

type Props = {
  img: string;
  name: string;
  percent: string;
};

const BigCard = ({ img, name, percent }: Props) => (
  <div className={styles.wrapperBigCard}>
    <div className={styles.imgWrapper}>
      <img src={img} alt="prize" />
    </div>

    <div>
      <div className={styles.row}>
        <span>
          <Text idT={percent} />
        </span>

        <img src="/assets/question.svg" alt="?" />
      </div>

      <div className={styles.line} />

      <div className={cln(styles.row, styles.center)}>
        <img src="/assets/usd.svg" alt="$" />

        <div className={styles.left}>
          <Text idT={name} />
        </div>
      </div>
    </div>
  </div>
);

export default BigCard;
