import Balance from 'components/Balance';
import ActionButtons from './components/ActionButtons';
import Carousel from './components/Carousel';
import Prizes from './components/Prizes';
import HOCInitGame from './HOC/HOCInitGame';

function App() {
  return (
    <HOCInitGame>
      <>
        <Carousel />

        <ActionButtons />

        <Balance />

        <Prizes />
      </>
    </HOCInitGame>
  );
}

export default App;
