import { create } from 'zustand';
import { ZustandLanguageStore } from './types';

export const useZustandLanguagesStore = create<ZustandLanguageStore>(
  (set, get) => ({
    languages: null,

    setLanguages: (languages) => {
      set({ languages });
    },

    getAndSetLanguages: async (lang) => {
      try {
        const languageResponse = await fetch(
          `${window.location.origin}/languages/${lang}.json`,
          {
            method: 'GET',
            cache: 'no-cache',
          }
        );

        const languages = await languageResponse.json();

        set({ languages });
      } catch (error) {
        if (lang === 'en') {
          throw new Error();
        }
        try {
          const languageResponse = await fetch(
            `${window.location.origin}/languages/en.json`,
            {
              method: 'GET',
              cache: 'no-cache',
            }
          );

          const languages = await languageResponse.json();

          set({ languages });
        } catch (error) {
          console.error('CRITICAL ERROR LANGUAGE NOT FOUND');
        }
      }
    },

    setInitLanguage: () => {
      const localLanguage = navigator.language.split('-')[0] || 'en';

      get().getAndSetLanguages(localLanguage);
    },
  })
);
