export interface ZustandAppStateStore extends ZustandAppStateEntities {
  setAppState: (s: EAppState) => void;
}

interface ZustandAppStateEntities {
  state: EAppState;
}

export const enum EAppState {
  Loading,
  Verification,
  Available,
}
