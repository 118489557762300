import { useState, useEffect } from 'react';
import cln from 'classnames';

import styles from './Carousel.module.css';

import { useZustandGameStore } from '../../store/game/store';
import {
  getGameResultSelector,
  getGameStateSelector,
  runGameUiAction,
  setGameStateAction,
} from '../../store/game/selectors';
import { ETH, LIST, LOSS, WIN } from '../../store/game/constants';
import { EGameState } from '../../store/game/types';
import { getRandomInt } from '../../utils/shared';
import Text from 'components/Text';

// Add spin event and Spin end

const Carousel = () => {
  const [list, setList] = useState(LIST);

  const [transform, setTransform] = useState<string>('');

  const gameResult = useZustandGameStore(getGameResultSelector);

  const gameState = useZustandGameStore(getGameStateSelector);

  const setGameState = useZustandGameStore(setGameStateAction);

  const runGameUi = useZustandGameStore(runGameUiAction);

  useEffect(() => {
    if (gameState !== EGameState.PrepareGameInfo || !gameResult) {
      return;
    }

    const isResult = list.find((l) => l.isResult);

    if (isResult) {
      return;
    }

    const loss = new Array(20).fill(LOSS);

    const btc = {
      img: '/assets/btc.webp',
      name: '100.00',
    };

    const neighbourhood = [LOSS, WIN, ETH];

    const nearV = neighbourhood[getRandomInt(0, 2)];

    setList([...list, ...loss, gameResult, nearV, LOSS, btc, btc]);
  }, [gameState, gameResult, list, runGameUi]);

  useEffect(() => {
    if (gameState !== EGameState.PrepareGameInfo) {
      return;
    }
    const isResult = list.find((l) => l.isResult);

    if (!isResult) {
      return;
    }
    runGameUi();
  }, [list, gameState, runGameUi]);

  useEffect(() => {
    if (gameState !== EGameState.StartGame) {
      return;
    }

    const el = document.getElementById('win_item') as any;

    if (!el) {
      return;
    }

    setTransform(`calc(-${el.offsetLeft}px + var(--width) + 2px)`);
  }, [gameState, list]);

  useEffect(() => {
    if (gameState !== EGameState.ResultGame) {
      return;
    }

    const baseList = list.slice(-6);

    const removedWinMarkers = baseList.map((l) => ({ ...l, isResult: false }));

    setTransform('');
    setList(removedWinMarkers);

    if (gameResult?.prize) {
      setGameState(EGameState.WinAnimation);

      return;
    }

    setGameState(EGameState.Idle);
  }, [gameResult, gameState, list, setGameState]);

  return (
    <div className={styles.wrapperCarousel}>
      <div className={cln(styles.lineBg, styles.top)} />
      <div className={styles.winBorder}>
        <img
          src="/assets/up.webp"
          alt="top arrow"
          className={styles.topArrow}
        />
        <img
          src="/assets/down.webp"
          alt="top arrow"
          className={styles.bottomArrow}
        />
        <img
          src="/assets/window-mobile.svg"
          alt="top arrow"
          className={styles.border}
        />
      </div>
      <div className={cln(styles.lineBg, styles.bottom)} />

      <div className={styles.content}>
        <div
          className={styles.container}
          style={{
            transition: transform ? '7s' : '0s',
            transform: transform ? `translateX(${transform})` : undefined,
          }}
        >
          {list.map(({ img, name, isResult = false }, inx) => (
            <div
              key={inx}
              className={cln(styles.item, {
                [styles.win]:
                  gameState === EGameState.WinAnimation && inx === 1,
              })}
              id={isResult ? 'win_item' : undefined}
            >
              <img src={img} alt={name} />

              <div className={styles.line} />
              <div className={styles.cardPrize}>
                {gameState === EGameState.WinAnimation && inx === 1 ? (
                  <span>
                    {Math.floor(gameResult?.prize || 0)}{' '}
                    {gameResult?.isRealMoney ? (
                      <Text idT="realMoney" />
                    ) : (
                      <Text idT="coin" />
                    )}
                  </span>
                ) : (
                  <span>
                    <Text idT={name} />
                  </span>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Carousel;
