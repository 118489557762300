import React, { useCallback, useEffect } from 'react';

import Loader from '../../components/Loader';

import { ELootBoxCommand } from './types';
import { useZustandAppStateStore } from '../../store/appState/store';
import { setAppStatusAction } from '../../store/appState/selectors';
import { EAppState } from '../../store/appState/types';
import { useZustandGameStore } from '../../store/game/store';
import {
  setBalanceAction,
  setGameResultAction,
  setGameStakeInfoAction,
} from '../../store/game/selectors';
import { getValueByIdFromUrl } from 'utils/shared';
import { getAndSetLanguagesAction } from 'store/languages/selectors';
import { useZustandLanguagesStore } from 'store/languages/store';

const DISABLE_GAME = window.innerWidth > 600;

const HOCInitGame = ({ children }: { children: React.ReactElement }) => {
  const setAppStep = useZustandAppStateStore(setAppStatusAction);

  const setStakeInfo = useZustandGameStore(setGameStakeInfoAction);

  const setGameResult = useZustandGameStore(setGameResultAction);

  const setBalance = useZustandGameStore(setBalanceAction);

  const getAndSetLanguages = useZustandLanguagesStore(getAndSetLanguagesAction);

  const handleGameSettings = useCallback(
    (payload: {
      currencyName: string;
      settings: { bets: { bet: number }[] };
    }) => {
      const { settings, currencyName } = payload;

      const bets = settings.bets.map(({ bet }) => +bet);

      if (!bets.length) {
        return;
      }

      setStakeInfo({ bets, currencyName, stake: bets[0] });
    },
    [setStakeInfo]
  );

  const parseMessage = useCallback(
    (e: any) => {
      try {
        const { data } = e;
        const { type, payload } = data;

        switch (type) {
          case ELootBoxCommand.VerificationStatus:
            setAppStep(EAppState.Available);
            break;
          case ELootBoxCommand.GameResult:
            setGameResult(payload);
            break;
          case ELootBoxCommand.BonusBalance:
            setBalance(payload.bonusCoin);
            break;
          case ELootBoxCommand.SetGameSettings:
            return handleGameSettings(payload);

          default:
            break;
        }
      } catch (error) {
        console.warn('🚀 loot box game miss handler :', error);
      }
    },
    [handleGameSettings, setAppStep, setGameResult]
  );

  useEffect(() => {
    window.addEventListener('message', parseMessage, true);

    const lang = getValueByIdFromUrl('lang', window.location.href) || 'en';

    getAndSetLanguages(lang);

    window?.parent?.postMessage(
      {
        type: ELootBoxCommand.GameIsLoaded,
        payload: {
          status: true,
        },
      },
      '*'
    );

    return () => {
      window.removeEventListener('message', parseMessage, true);
    };
  }, [parseMessage]);

  if (DISABLE_GAME) {
    console.log('🚀 ~ HOCInitGame ~ DISABLE_GAME:', DISABLE_GAME);

    return <Loader />;
  }

  return (
    <>
      <Loader />

      {children}
    </>
  );
};

export default HOCInitGame;
