import { create } from 'zustand';
import { EGameState, ZustandGameStore } from './types';
import { sleep } from '../../utils/shared';
import { LOSS, WIN } from './constants';

export const useZustandGameStore = create<ZustandGameStore>((set, get) => ({
  state: EGameState.Idle,
  gameResult: null,
  bets: [],
  stake: 0,
  currencyName: '',
  balance: 0,

  setStake: (stake) => {
    set({ stake });
  },
  setBalance: (balance) => {
    set({ balance });
  },

  setStakeInfo: ({ bets, stake, currencyName }) => {
    set({ stake, currencyName, bets });
  },

  runGame: async () => {
    const { state } = get();

    if (state === EGameState.ResultGame) {
      return;
    }

    try {
      set({ state: EGameState.PrepareGameInfo, gameResult: null });
    } catch (error) {
      set({ state: EGameState.Idle });
    }

    // Request and get information
  },
  setGameResult(data) {
    if ('isError' in data) {
      set({
        gameResult: null,
        state: EGameState.Idle,
      });

      return;
    }

    const { isWin, prize, isRealMoney = false } = data;

    if (!isWin) {
      set({ gameResult: { ...LOSS, isResult: true, prize: 0 } });

      return;
    }
    set({ gameResult: { ...WIN, isResult: true, prize, isRealMoney } });
  },

  runUiGame: async () => {
    set({ state: EGameState.StartGame });

    await sleep(7000);

    set({ state: EGameState.ResultGame });
  },

  setGameState: (state) => {
    set({ state });
  },
}));
